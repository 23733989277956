/* .App {
  text-align: center;
} */
::-webkit-scrollbar {
  width: 5px !important;
  height: 10px !important;

  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666;
}

body h4,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

body {
  font-family: "ABBVoice" !important;
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header>.ABB_CommonUX_LoginScreen__logo {
  /* background: url(/logo.png) no-repeat !important; */
  background-size: contain;
  display: block;
  height: 50px;
  width: 80px;
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__content {
  padding: 16px 32px 8px 32px !important;
}

.light-theme {
  background-color: #fff;
  color: #1f1f1f;
}

.dark-theme {
  color: #dbdbdb !important;
  background-color: #333 !important;
}

.content-dark-theme {
  color: #dbdbdb !important;
  background-color: #0f0f0f !important;
}

.dark-view-container {
  background-color: #333 !important;
}

iframe {
  pointer-events: none;
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__light {
  background: #ececec;
  color: #1f1f1f;
}

/* For the New UI */
body {
  background-color: #ececec !important;
}

.row-flex {
  display: flex;
  flex-direction: row;

}

.sidebarContainer {
  /* background-color:#ececec */
}

.MainContainer {
  flex: 1;
  /* background-color:#ececec; */
  padding: 5px;
  margin-bottom: 20px;
}

.CircularProgressbar {
  width: 23px !important;
}



.screenTabs {
  display: flex;
  flex-direction: "row";
  column-gap: 10px;
  font-size: 20px;
  font-weight: 100;
  margin-top: 20px;
}

.viewOptions:hover {
  border-bottom-color: #d9d9d9;
  color: #585858;
}

.viewOptions {
  transition: 0.2s;
  cursor: pointer;
  padding: 0px 10px;
  height: 30px;
  color: #868686;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  font-size: 18px;
}

.viewOptions.active {
  border-bottom-color: #2c2c2c;
}

.ViewContainer {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  transition: 0.3s;
  overflow-y: auto;
  /* border: 10px solid #ececec; */
  width: 100%;
}

.ViewContainer table{
  font-family: 'ABBVoice';
}
/* 
#listedges tr td {
width: 100px !important;
  max-width: 200px !important;
} */

td input {
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  padding: 3px;
  width: 95%;
}

.tableHeading td {
  color: rgb(94, 94, 94);
  border-bottom: 1px solid #d2d2d2;
  font-weight: 700;
}

td {
  padding: 5px 2px;
  font-weight: 500;
}


.addApplicationPannel {
  overflow: hidden;
  transition: .3s;
  border-left: 1px solid #f0f0f0;
  padding-left: 5px;
}
.addApplicationPannel a {
  font-size: 14px;
  font-family: 'ABBVoice';
}

.addApplicationPannel td {
  padding: 3px;
}
.overFlowText,
.app-license-content>h2 {
  white-space: nowrap;
  max-width: 100%;
  min-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.app-license-content>h2 {
  line-height: 30px;
}

#tableBox table{
  font-family: 'ABBVoice';
}

#tableBox th{
    font-size: 18px;
}

#tableBox td{
  font-size: 15px;
}

 #tableBox .ant-table-body, .tableBox .ant-table-body{
  overflow-y: auto !important;
}

.react-switch-handle>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsOptions {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}

hr {
  height: 1px;
  border: none;
  background: #D2D2D6;
}



.Alloptions {
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: space-between;
  /* column-gap: 30px; */
  padding: 10px 10px;
  /* margin-top: 20px; */
  /* width: 170px; */
}

.Alloptions span {
  cursor: pointer;
  padding: 5px 3px;
  /* border-left: 4px solid transparent; */
  border-bottom: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  font-size: 15px;
  transition: 0.3s;
}

.Alloptions span:hover{
  border-bottom: 2px solid #d2d2d2;

}

.Parent_settings_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.setings_container {
  width: 100%;
  /* padding-top: 40px; */
}

.edge-settingsinfo {
  margin-top: 0px;
  margin-left: 0px;
}


.ant-popover-title {
  font-size: 20px;
}

.ant-modal-footer {
  display: none;
}

.Hyper_link {
  color: #3366FF;
}

.Heading_container {

  background: white;
  padding: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 20px;
}

.hiddenModules {
  visibility: collapse;
}

.modulecontainer {
  display: flex;
  flex-Direction: column;

}

.modulecontainer span {
  /* border-bottom: 1px solid #e2e2e2; */
}

.noborderBelow td {
  border-bottom: none;
}

.hiddenParentUnderline {
  border-bottom: none;
}

.displaynone {
  display: none;
}


.notification {
  /* position: absolute;
  top: 50px;
  right: 1.5%; */
  background-color: #fff;
  box-shadow: 1px 3px 14px -4px #e2e2e2;
  border-radius: 8px;
  /* padding: 10px 15px; */

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  column-gap: 10px;
  /* Z-INDEX: 100; */
  /* width: 22%; */
}



.fixed_header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 100px;
}
.fixed_header thead tr {
  display: block;
}
.fixed_header thead {
  background: black;
  color: #fff;
}
.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  /* width: 20% !important; */
}

.staticCell{
 display: inline-table;
}

.edgeNameColumn{
  /* background:yellow; */
  width: 29.3%;
}

.edgeConnColumn{
  /* background:pink; */
  width: 30%;
}

.edgeLocationColumn{
  width: 20%;
/* background: red; */
}

.edgeCPUColumn,.edgeMemoryColumn{
  width: 10%;
  /* background-color: blueviolet; */
}

.staticCell > .overFlowText{
  padding: 0px 5px;
}

.ABB_CommonUX_AppContainer__root{
  overflow: auto !important;
}

.ViewContainer th{
  font-size: 18px;
}

.ViewContainer td {
  font-size: 15px;
}
/* overall Text fontsize */
.heading_1{
  font-size: 22px !important;
  font-family: 'ABBVoice';
}

.heading_2{
  font-size: 18px !important;
  font-family: 'ABBVoice';
}

.text_1{
  font-size: 15px !important;
  font-family: 'ABBVoice';
}

.text_2{
  font-size: 13px !important;
  font-family: 'ABBVoice';
}


.ant-form label{
  font-size: 15px;
  font-family: 'ABBVoice';
}

.ant-form input{
  font-size: 15px;
  font-family: 'ABBVoice';
}

.ant-form select{
  font-size: 15px;
  font-family: 'ABBVoice';
}

.ant-select-selector{
  font-family: "ABBVoice" !important;
  font-size: 15px;

}

.ant-popover{
  z-index: 100 !important;
}


.availableAppSectionHeading{
 
  color: rgb(30 30 30) !important;
  background: #f9f9f9;
  padding: 10px 5px !important;
  font-weight: 700;
  width: 98% !important;
  border-bottom: 1px solid #f0f0f0;
  border-top-right-radius: 6px;
  overflow: hidden;
  border-top-left-radius: 6px
}

#allnotiContainer{
  transition: .3s;
  display: flex;
  flex-direction: column-reverse;
}

.customModal{
  background: rgb(255, 255, 255);
  box-shadow: rgb(226, 226, 226) 1px 1px 10px 0px;
  border-radius: 10px;
  max-height: 380px;
  overflow: auto;
  position: absolute;
  right: 24px;
  z-index: 100;
  width: auto;
  max-width: 90%;
}
/* .customModal::before {
  content: ">";
  background-color: red;
} */


.uploader{
  height: 200px !important;
  width: 400px;
  display: block;
  background-color:'#e2e2e2';
}

.uploader .ant-upload-btn {

  background: #d1ebff !important;
  font-family: 'ABBVoice';
} 

.uploader .anticon {
  /* color: #d1ebff !important; */
}

.uploadBtn {
  background: rgb(209, 235, 255);
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
  width: 90%;
  font-family: ABBVoice;
}

.uploadBtn > #file-upload-button{
  font-family: ABBVoice !important; 
  background:red;
}